import {Layout} from "../../../components/Layout"
import {Page} from "../../../components/PrivateRoutePage"
import {RGBDualPage} from "../RGBDualPageNext"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {GrowGrid, ResponsiveContainer} from "../../../components/Shared"
import {useBreadcrumb} from "../../../hooks/useBreadcrumb"
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    LinearProgress,
    TextField,
    Typography
} from "@material-ui/core"
import {GatsbyImage, StaticImage} from "gatsby-plugin-image"
import {AiFillStar} from "@react-icons/all-files/ai/AiFillStar"
import React, {Fragment, useEffect, useState} from "react"
import styled from "@emotion/styled"
import {InlineMdRaw, Md, TransMdTypo} from "../../../components/i18n/TransMdTypo"
import {Link, useI18nLink} from "../../../components/i18n/I18nLink"
import {graphql, navigate, useStaticQuery} from "gatsby"
import {File, Query} from "../../../../graphql-types"
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js"
import {TFunction} from "react-i18next"
import {TextCenter} from "../../diy/components/Shared"
import YouTube from "react-youtube"
import {Autocomplete} from "@material-ui/lab"
import {countries, Country} from "./countries"
import {RRGBDOption, RRGBDOptions} from "./Types"
import {orderApi} from "./OrderApi"
import {useDispatch} from "react-redux"
import {notification} from "../../notification/notificationSlice"
import {usePatreon} from "../../patreon/patron/usePatreon"
import {RecalboxRGBDualManualPage} from "../RecalboxRGBDualManualPage"
import {RRGBDItem} from "../../../../../patreon-api/src/stock/StockApi"
import {PatronInfo} from "../../../../../patreon-api/src/patron/Patron"
import {FaInfoCircle} from "@react-icons/all-files/fa/FaInfoCircle"
import {AiOutlineTag} from "@react-icons/all-files/ai/AiOutlineTag"
import {AiOutlineLoading} from "@react-icons/all-files/ai/AiOutlineLoading"
import {PatreonPage} from "../../patreon/PatreonPage";

const PAYPAL_CLIENT_ID = process.env.GATSBY_PAYPAL_CLIENT_ID || "AUnkykBuBeLr-NYip9RvU9a6HQcw83bIZ6auVurLgHH7dfnSByG_d9S3FHvPv4k5EkbwG7vtRoXQSJZk"
const SPECIAL_OFFER_PERCENT = 0
const GATSBY_SCART_DISCOUNT = process.env.GATSBY_SCART_DISCOUNT == "true"

const Rating = () => {
    return <div css={{color: "yellow", marginBottom: "1em"}}>
        <AiFillStar></AiFillStar><AiFillStar></AiFillStar><AiFillStar></AiFillStar><AiFillStar></AiFillStar><AiFillStar></AiFillStar>
    </div>
}

const Stock = (props: { options: RRGBDOptions, loaded: boolean }) => {
    const {t} = useTranslation()
    const {options, loaded} = props
    const text = options.board.stock > 0 ? `${options.board.stock} ${t("rrgbdorder^in stock")}` : t("rrgbdorder^Out of stock...")
    return <Grid container alignItems={"center"} spacing={2}>
        <GrowGrid item>
            <LinearProgress variant="determinate" value={Math.min(options.board.stock, 100)}/>
        </GrowGrid>
        <Grid item>
            {loaded ?
                <Typography variant={"subtitle1"}>{text}</Typography>
                :
                <Loading/>
            }
        </Grid>
    </Grid>
}

const Loading = () => <AiOutlineLoading className={"loading"}/>

const Product = () => {
    const {t} = useTranslation()
    return <div css={{marginBottom: "2em"}}>
        <Typography variant={"h3"}>Recalbox RGB Dual</Typography>
        <Typography variant={"subtitle1"}>{t("rrgbdorder^The ultimate CRT experience")}</Typography>
    </div>
}

const PriceUnit = styled.span(({theme}) => ({
    color: theme.palette.secondary.main
}))

const Pricing = (props: { price: number, small?: boolean }) => {
    return <Typography variant={props.small ? "h6" : "h3"} component={"span"} gutterBottom>{props.price.toFixed(2)}
        <PriceUnit>€</PriceUnit></Typography>
}

const Option = (props: { desc: string, price: number, priceUnit: string, inStock: boolean, loaded: boolean, onInfo: () => void }) => {
    const {t} = useTranslation()
    const {desc, price, priceUnit, inStock, loaded, onInfo} = props
    return <Typography><InlineMdRaw children={desc}/> {loaded ? (!inStock && t("rrgbdorder^(out of stock)")) : <Loading/>}
        {inStock &&
            <span css={{fontWeight: "bold"}}>(+{price}
                <PriceUnit>{priceUnit}</PriceUnit>)</span>
        }
        &nbsp;<FaInfoCircle css={{cursor: "pointer"}} onClick={(event) => {
            event.preventDefault()
            onInfo()
        }}/>
    </Typography>
}

const OptionDialog = ({
                          open,
                          onClose,
                          option
                      }: { open: boolean, onClose: () => void, option: RRGBDOption & { image: React.ReactNode } }) => {
    const {t} = useTranslation()
    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t(`rrgbdorder^${option.name}`)}</DialogTitle>
        <DialogContent>
            {option.image}
            <DialogContentText>
                <TransMdTypo children={`rrgbdorder^${option.description}`}/>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                {t("rrgbdorder^Close")}
            </Button>
        </DialogActions>
    </Dialog>
}

type RRGBDOptionsWithImage = RRGBDOptions & { [key in RRGBDItem]: RRGBDOptionWithImage }
type RRGBDOptionWithImage = RRGBDOption & { image: React.ReactNode }

const OrderOptions = (props: { t: TFunction, quantity: number, options: RRGBDOptionsWithImage, freeScart: boolean, handleCheckBox: (event: React.ChangeEvent<HTMLInputElement>, option: RRGBDItem) => void, loaded: boolean }) => {
    const totallyOutOfStock = props.options.board.stock === 0
    const [state, setState] = useState({open: false, option: null as RRGBDOptionWithImage | null})

    const handleInfo = (option: RRGBDOptionWithImage) => {
        setState({open: true, option})
    }
    return <FormControl><FormGroup css={{marginBottom: "2em"}}>
        {state.open && state.option &&
            <OptionDialog open={state.open} onClose={() => setState({open: false, option: null})}
                          option={state.option}/>
        }
        {Object.entries(props.options).filter(option => option[1].selectable).map((option) => {
            const itemInStock = option[1].stock !== 0
            if(!itemInStock) return <Fragment/>

            const desc = option[1].selected ? `${props.quantity}x ` + props.t(`rrgbdorder^${option[1].name}`) : props.t(`rrgbdorder^${option[1].name}`)
            const isFreeScart = option[0] === "shieldedscart" && props.freeScart
            if(isFreeScart){
                return <FormControlLabel key={option[0]}
                                         control={<Checkbox value={option[1].selected} checked={option[1].selected}/>}
                                         label={<Option desc={`${desc} Free`} price={0}
                                                        onInfo={() => handleInfo(option[1])}
                                                        priceUnit={"€"} inStock={itemInStock} loaded={props.loaded}/>}/>
            }else {

                return <FormControlLabel key={option[0]}
                     control={<Checkbox value={option[1].selected} checked={option[1].selected}
                                        disabled={!itemInStock || totallyOutOfStock}
                                        onChange={(e) => props.handleCheckBox(e, option[0] as RRGBDItem)}/>}
                     label={<Option desc={desc} price={option[1].price}
                                    onInfo={() => handleInfo(option[1])}
                                    priceUnit={"€"} inStock={itemInStock} loaded={props.loaded}/>}/>
            }


        })}
    </FormGroup></FormControl>
}

const Description = styled.div(({theme}) => ({
    margin: "1em 0 1em 0",
}))

const DescTypo = styled(TransMdTypo)({
    fontSize: "1em",
    marginBottom: "1em"
})

const image = (images: File[], options: RRGBDOptions) => {
    const image =
        images.find((image) => image.name === `board-${options.case.selected ? 1 : 0}-${options.gpio.selected ? 1 : 0}-${options.shieldedscart.selected ? 1 : 0}-${options.scart.selected ? 1 : 0}-${options.vga.selected ? 1 : 0}-${options.case400.selected ? 1 : 0}`)
    return image
}

const OrderTitle = ({
                        first,
                        children,
                        className = ""
                    }: { first?: boolean, children: React.ReactNode, className?: string }) => {
    return <Typography className={className} css={{marginBottom: "0.3em", marginTop: first ? "0" : "1em"}}
                       variant={"h5"}
                       children={children}/>
}


const OrderCountry = (props: { options: RRGBDOptions, onSelect: (country: Country | null) => void }) => {
    const {t} = useTranslation()
    return <Autocomplete
        disablePortal
        options={countries}
        getOptionLabel={(option) => option.country}
        onChange={(event, value) => props.onSelect(value)}
        renderInput={(params) => <TextField {...params} label={t("rrgbdorder^Select a country")}/>}
    />
}

const ValidatedDialog = (props: { open: boolean }) => {
    const {t} = useTranslation()
    const {getLink} = useI18nLink()
    const handleClose = () => {
        navigate(getLink(RecalboxRGBDualManualPage.getUrl()))
    }
    return <Dialog
        open={props.open}
        onClose={handleClose}
    >
        <DialogTitle>{t("rrgbdorder^Thank you for your order!")}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <TransMdTypo>rrgbdorder^Thank you for your order details</TransMdTypo>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                {t("rrgbdorder^See the manual")}
            </Button>
        </DialogActions>
    </Dialog>
}

const OrderQuantity = (props: { totalItems: number, inStock: boolean, quantity: number, onChange: (quantity: number) => void }) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <Grid container spacing={1} wrap={"nowrap"} alignItems={"center"}>
            <Grid item>
                <TextField
                    disabled={!props.inStock}
                    id="outlined-number"
                    type="number"
                    label={t("rrgbdorder^Quantity")}
                    variant={"outlined"}
                    size={"small"}
                    css={{textAlign: "right", width: "5em"}}
                    value={props.quantity}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event.target.valueAsNumber)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item>
                <TextCenter>
                    x
                </TextCenter>
            </Grid>
            <Grid item>
                <Pricing price={props.totalItems}/>
            </Grid>
        </Grid>
    </React.Fragment>
}

const initalOptions: RRGBDOptionsWithImage = {
    "board": {
        price: 40,
        selectable: false,
        selected: true,
        stock: 0,
        weight: 50,
        name: "Recalbox RGB Dual",
        description: "OFFICIAL Recalbox RGB Dual, compatible with Raspberry Pi 4, 3, Zero 2 and 400 (requires GPIO extension), Scart output + VGA output, supports 15kHz and 31kHz, 60Hz and 50Hz, Pixel Factory, BoomBox",
        image: <StaticImage src={"../../../data/images/rgb-dual-order/options-solo/board.jpg"} alt={"Board image"}/>
    },
    "case": {
        price: 30,
        selectable: true,
        selected: false,
        stock: 0,
        weight: 70,
        name: "**RPi 4 and 3** 3D Printed Case (Black)",
        description: "The official 3D printed case for your Raspberry Pi 4 and 3 and your Recalbox RGB Dual! \n\nPrinted in matte black and with the logo in color, this case allows to protect your installation, and has been designed to optimize the circulation of hot air.",
        image: <StaticImage src={"../../../data/images/rgb-dual-order/options-solo/case.jpg"} alt={"Case image"}/>
    },
    "casew": {
        price: 30,
        selectable: true,
        selected: false,
        stock: 0,
        weight: 70,
        name: "**RPi 4 and 3** 3D Printed Case (White)",
        description: "The official 3D printed case for your Raspberry Pi 4 and 3 and your Recalbox RGB Dual! \n\nPrinted in white and with the logo in color, this case allows to protect your installation, and has been designed to optimize the circulation of hot air.",
        image: <StaticImage src={"../../../data/images/rgb-dual-order/options-solo/casew.png"} alt={"Case white image"}/>
    },
    "case400": {
      price: 30,
      selectable: true,
      selected: false,
      stock: 0,
      weight: 70,
      name: "**RPi 400** 3D Printed Case (Black)",
      description: "The official 3D printed case for your Raspberry Pi 400! \n\nPrinted in matte black and with the logo in color.",
      image: <StaticImage src={"../../../data/images/rgb-dual-order/options-solo/case400.jpg"} alt={"Case400 image"}/>
    },
    "case400w": {
      price: 30,
      selectable: true,
      selected: false,
      stock: 0,
      weight: 70,
      name: "**RPi 400** 3D Printed Case (White)",
      description: "The official 3D printed case for your Raspberry Pi 400! \n\nPrinted in white and with the logo in color.",
      image: <StaticImage src={"../../../data/images/rgb-dual-order/options-solo/case400w.jpg"} alt={"Case400 white image"}/>
    },
    "gpio": {
        price: 10,
        selectable: true,
        selected: false,
        stock: 0,
        weight: 20,
        name: "GPIO Extender",
        description: "The GPIO extension allows you to disconnect the Recalbox RGB Dual from the Rasbperry Pi. It will be useful if you have a Raspberry Pi 400, if you have a compatible box, or if you often disconnect and reconnect the Recalbox RGB Dual from your Raspberry Pi.",
        image: <StaticImage src={"../../../data/images/rgb-dual-order/options-solo/gpio.jpg"}
                            alt={"GPIO extender image"}/>

    },
    "shieldedscart": {
        price: 18,
        selectable: true,
        selected: false,
        stock: 0,
        weight: 360,
        name: "Shielded Scart Cable",
        description: "The shielded SCART cable, selected and validated by our team, ensures the best audio and video quality on your installation. \n\nNo more picture noise or hissing! It is **HIGHLY RECOMMENDED** to enjoy the ultimate Recalbox RGB Dual experience.",
        image: <StaticImage src={"../../../data/images/rgb-dual-order/options-solo/shieldedscart.jpg"}
                            alt={"Shielded Scart image"}/>
    },
    "scart": {
        price: 8,
        selectable: true,
        selected: false,
        stock: 0,
        weight: 150,
        name: "Scart Cable",
        description: "Connect your Recalbox RGB Dual to your TV.",
        image: <StaticImage src={"../../../data/images/rgb-dual-order/options-solo/scart.jpg"} alt={"Scart image"}/>

    },
    "vga": {
        price: 8,
        selectable: true,
        selected: false,
        stock: 0,
        weight: 150,
        name: "VGA Cable",
        description: "Connect your Recalbox RGB Dual to your VGA monitor.",
        image: <StaticImage src={"../../../data/images/rgb-dual-order/options-solo/vga.jpg"} alt={"VGA image"}/>
    }
}

const ScartDiscount = (props: { applied: boolean, quantity: number }) => {
    const {t} = useTranslation()
    return props.applied ?
      <Typography>{`${props.quantity}x ${t("rrgbdorder^Free Shielded Scart Cable")}`}</Typography>
          :
      <Typography
        css={{textDecoration: "line-through"}}>{t("rrgbdorder^Free Shielded Scart Cable")}</Typography>
}

const OrderPatronDiscount = (props: { isPatron: boolean, discount: number, specialOfferPercent: number }) => {
    const {t} = useTranslation()
    if (props.specialOfferPercent > 0) {
        if (!props.isPatron) {
            return <Typography>{t("rrgbdorder^Discount: -25% on the board")}: <Pricing small
                                                                                       price={-props.discount}/></Typography>
        } else {
            return <Typography>{t("rrgbdorder^Discount: -25% on the board and -10% patron")}: <Pricing small
                                                                                                       price={-props.discount}/></Typography>
        }
    } else {
        if (!props.isPatron) {
            return <Typography
                css={{textDecoration: "line-through"}}>{t("rrgbdorder^Patron discount")} (-10%)</Typography>
        } else {
            return <Typography>{t("rrgbdorder^Patron discount")} (-10%): <Pricing small
                                                                                  price={-props.discount}/></Typography>
        }
    }
}
type StateType = { quantity: number, options: RRGBDOptionsWithImage, shippingCountry: Country | null, isPatron: boolean, patron: PatronInfo | null, orderValidated: boolean, loaded: boolean }

const RecalboxRGBDualOrder = () => {
    const allFiles = useStaticQuery<Query>(graphql`
    query RRGBDualOrder {
      allFile (
        filter: {relativeDirectory: {eq: "images/rgb-dual-order/options"}, name: {glob: "board-*"}}
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `).allFile.edges.map(_ => _.node)
    const [state, setState] = useState<StateType>({
        quantity: 1,
        options: initalOptions,
        shippingCountry: null,
        isPatron: false,
        patron: null,
        orderValidated: false,
        loaded: false
    })

    const {t, i18n} = useTranslation()
    const dispatch = useDispatch()
    const patreon = usePatreon()
    const breadCrumb = useBreadcrumb(RecalboxRGBDualOrderPage)

    const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>, option: RRGBDItem) => {
        const newOptions = {...state.options}
        newOptions[option].selected = event.target.checked
        setState({...state, options: newOptions})
    }
    const handleQuantity = (quantity: number) => {
        if (quantity > 4) {
            dispatch(notification(t("rrgbdorder^You can order a maximum of 4 Recalbox RGB Dual"), "warning"))
            return
        } else {
            if (quantity >= 1)
                setState({...state, quantity})
        }
    }

    const handleShippingCountry = (country: Country | null) => {
        setState({...state, shippingCountry: country})
    }

    const handlePaymentSuccess = (payerEmail: string) => {
        orderApi.orderSuccess(state.quantity, state.options, payerEmail, i18n.language, state.patron?.email)
        setState({...state, orderValidated: true})
    }

    const checkCanOrder = () => {
        if (!state.shippingCountry)
            dispatch(notification(t("rrgbdorder^Please select a shipping country."), "warning"))
    }


    useEffect(() => {
        orderApi.getStocks().then(stocks => {
            const currentOptions = {...state.options}
            Object.entries(stocks).map(item => {
                if (item[0] in currentOptions)
                    currentOptions[item[0] as RRGBDItem].stock = item[1].stock
            })
            patreon.getUserInfo().then((patronInfo) => {
                setState({
                    ...state,
                    options: currentOptions,
                    isPatron: true,
                    patron: patronInfo,
                    loaded: true
                })
            }).catch(() => {
                setState({
                    ...state,
                    options: currentOptions,
                    isPatron: false,
                    patron: null,
                    loaded: true
                })
            })
        }).catch(e => {
            setState({
                ...state,
                loaded: true
            })
        })
    }, [])

    const currentlyInStock = orderApi.inStock(state.options)
    const currentImage = image(allFiles, state.options)
    const total = orderApi.calculateForPaypal(state.quantity, state.options, state.shippingCountry, state.isPatron, SPECIAL_OFFER_PERCENT, GATSBY_SCART_DISCOUNT)
    const appliedScartDiscount = orderApi.shouldApplyScartDiscount(state.options, GATSBY_SCART_DISCOUNT)
    if(appliedScartDiscount && !state.options["shieldedscart"].selected) {
        const newOptions = {...state.options}
        newOptions["shieldedscart"].selected = true
        setState({...state, options: newOptions})
    }
    return <Layout title={`${t("rrgbdorder^Recalbox RGB Dual")}`}
                   description={t("rrgbdorder^OFFICIAL Recalbox RGB Dual, compatible with Raspberry Pi 4, 3, Zero 2 and 400, Scart output + VGA output, supports 15kHz and 31kHz, 60Hz and 50Hz, Pixel Factory, BoomBox")}
                   imageUrl={allFiles.find(img => img.name === "board-1-1-1-1")?.childImageSharp?.gatsbyImageData.images.fallback.src}>
        <ResponsiveContainer>
            <div css={{marginBottom: "3em"}}>{breadCrumb}</div>
            <ValidatedDialog open={state.orderValidated}/>
            <Grid container spacing={3} direction={"row-reverse"}>


                <Grid item xs={12} md={5}>
                    <Card>
                        <CardContent>
                            <OrderTitle>{t("rrgbdorder^Now available on the new Recalbox Shop!")}</OrderTitle>
                            <Button href={"https://shop.recalbox.com/collections/rgbdual"} variant={"outlined"}
                                    color={"secondary"}>{t("rrgbdorder^Buy Recalbox RGB DUAL")}</Button>
                        </CardContent>
                    </Card>
                </Grid>

{/*

                <Grid item xs={12} md={5}>
                    <Card>
                        <CardContent>
                            <Stock options={state.options} loaded={state.loaded}/>
                            <Product/>
                            <Grid container spacing={1} wrap={"nowrap"} alignItems={"center"}>

                                <Grid item>
                                    <Pricing price={state.options.board.price}/>
                                </Grid>
                            </Grid>


                            <Description>
                                <DescTypo>rrgbdorder^OFFICIAL Recalbox RGB Dual, compatible with Raspberry Pi 4, 3, Zero
                                    2 and 400
                                    (requires GPIO extension), Scart output + VGA output, supports 15kHz and 31kHz, 60Hz
                                    and 50Hz, Pixel
                                    Factory, BoomBox</DescTypo>

                                <OrderTitle>Options</OrderTitle>
                                <OrderOptions t={t} options={state.options} quantity={state.quantity}
                                              handleCheckBox={handleCheckBox}
                                              loaded={state.loaded}
                                              freeScart={appliedScartDiscount}/>
                                {state.loaded ? (!currentlyInStock &&
                                        <OrderTitle>{t("rrgbdorder^Back soon!")}</OrderTitle>) :
                                    <Loading/>}
                                {currentlyInStock &&
                                    <React.Fragment>
                                        <OrderTitle>{t("rrgbdorder^Quantity")}</OrderTitle>
                                        <OrderQuantity quantity={state.quantity} inStock={currentlyInStock}
                                                       totalItems={total.totalItems}
                                                       onChange={handleQuantity}/>

                                        <OrderTitle>{`${t("rrgbdorder^Shipping")}`}</OrderTitle>
                                        <OrderCountry options={state.options} onSelect={handleShippingCountry}/>

                                        <OrderTitle>{t("rrgbdorder^Order")}</OrderTitle>
                                        <Typography>{t("rrgbdorder^Items")}: <Pricing small
                                                                                      price={total.grandTotal}/></Typography>

                                        {GATSBY_SCART_DISCOUNT &&
                                            <ScartDiscount applied={appliedScartDiscount} quantity={state.quantity}/>
                                        }
                                        <OrderPatronDiscount isPatron={state.isPatron} discount={total.discount}
                                                             specialOfferPercent={SPECIAL_OFFER_PERCENT}/>

                                        <Typography>{t("rrgbdorder^Shipping")}: {total.shipping > 0 ?
                                            <Pricing small price={total.shipping}/>
                                            :
                                            <span>{t("rrgbdorder^Select a country")}</span>
                                        }
                                        </Typography>
                                        <Typography>Total: <Pricing
                                            price={total.grandTotalPlusShippingAndDiscount}/></Typography>
                                        <PayPalScriptProvider
                                            options={{
                                                currency: "EUR",
                                                "client-id": PAYPAL_CLIENT_ID
                                            }}>
                                            <PayPalButtons
                                                disabled={!total.canOrder}
                                                onClick={checkCanOrder}
                                                forceReRender={[state]}
                                                onShippingChange={(data, actions) => {
                                                    if (orderApi.validateShippingCountry(data.shipping_address?.country_code, state.shippingCountry)) {
                                                        return Promise.resolve()
                                                    }
                                                    dispatch(notification(t("rrgbdorder^Order was cancelled because the shipping country has changed."), "warning"))
                                                    return Promise.reject(t("rrgbdorder^Shipping country does not correspond"))
                                                }}
                                                style={{color: "blue"}}
                                                createOrder={(data, actions) => {
                                                    return actions.order.create({
                                                        purchase_units: total.items
                                                    })
                                                }}
                                                onApprove={(data, actions) => {
                                                    return actions.order!.capture().then((details) => {
                                                        handlePaymentSuccess(details.payer.email_address)
                                                    })
                                                }}
                                            />
                                        </PayPalScriptProvider>
                                    </React.Fragment>
                                }
                            </Description>
                        </CardContent>
                    </Card>
                </Grid>

*/}

                <Grid item xs={12} md={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <CardMedia>{currentImage &&
                                    <GatsbyImage image={currentImage.childImageSharp?.gatsbyImageData}
                                                 alt={""}/>}</CardMedia>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    {/*{GATSBY_SCART_DISCOUNT &&
                                      <Fragment>
                                        <OrderTitle first={GATSBY_SCART_DISCOUNT}><AiOutlineTag
                                          css={{color: "yellow"}}/> {t("rrgbdorder^Free shielded scart cable for the purchase of a Recalbox RGB Dual and a 3D printed case")}
                                            <AiOutlineTag css={{color: "yellow"}}/></OrderTitle>
                                        <DescTypo
                                          children={t("rrgbdorder^When ordering the Recalbox RGB Dual and a 3D printed case, we offer you our shielded scart cable that perfects the Recalbox RGB Dual experience with unmatched picture and sound!")} />
                                      </Fragment>
                                    }*/}
                                    {/*<OrderTitle first><AiOutlineTag
                                        css={{color: "yellow"}}/> {t("rrgbdorder^Patron Discount")}
                                        <AiOutlineTag css={{color: "yellow"}}/></OrderTitle>
                                    {state.isPatron ?
                                        <DescTypo
                                            children={t("rrgbdorder^Discount applied", {name: state.patron?.full_name})}/>
                                        :
                                        <React.Fragment>

                                            <DescTypo
                                                children={t("rrgbdorder^Discount details")}/>
                                            <Button component={Link} to={PatreonPage.getUrl()} variant={"outlined"}
                                                    color={"secondary"}>{t("rrgbdorder^Become a patron")}</Button>
                                        </React.Fragment>
                                    }


                                    <OrderTitle><AiOutlineTag
                                      css={{color: "yellow"}}/> {t("rrgbdorder^Patron Discount")}
                                        <AiOutlineTag css={{color: "yellow"}}/></OrderTitle>
*/}

                                    <OrderTitle css={{marginTop: "1em"}}
                                                >{t("rrgbdorder^Description")}</OrderTitle>
                                    <DescTypo
                                        children={t("rrgbdorder^After the great success of the kickstarter, Recalbox RGB Dual is finally available!")}/>
                                    <DescTypo
                                        children={t("rrgbdorder^Add a SCART output and a VGA output to your Raspberry Pi 4, 400, 3 or Zero 2 and enter the pixel perfect experience!")}/>
                                    <YouTube videoId={"WfKYzuCHvLU"} css={{borderRadius: "1em"}}/>
                                    <Link css={{marginTop: "1em"}}
                                          to={RGBDualPage.getUrl()}>{t("rrgbdorder^Link to the full project description")}</Link>
                                    <DescTypo children={"rrgbdorder^Technical Specs"}/>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ResponsiveContainer>
    </Layout>
}


const getUrl = () => "/recalbox-rgb-dual/order"
const breadCrumb = {name: "Order", link: getUrl()}
export const RecalboxRGBDualOrderPage: Page = {
    Page: RecalboxRGBDualOrder,
    getUrl,
    breadCrumb,
    parentPage: () => RGBDualPage
}
